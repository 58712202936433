.knacklyBlock {
    width: 150px !important;
    min-width: 150px !important;
    border: 0;
    border-bottom: 1px solid rgba(203, 203, 203, 0);
    border-left: 1px solid rgba(203, 203, 203, 0);
}

.knacklyBlock:focus {
    outline: none !important;
}

.board {
    overflow: auto;
    width: 100%;
    height: 70vh !important;
    background: #CBCBCB;
    border: 1px solid rgba(0, 0, 0, 0.65)
}

.isKey {
    background: #F6F6F6 !important;
}

.w-30px {
    width: 30px;
}

.b-send {
    width: 1rem;
    position: absolute;
    right: .5rem;
    top: -0.1rem;
    color: #d9d9d9;
    transition: all .3s;
    z-index: 2;
}

.b-send:hover {
    color: #1890ff;
}

.isDragging {
    opacity: 0.2;
    background: #a5a5a5 !important;
}
