* {
    box-sizing: border-box;
}

.signin {
    background: white;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.welcome {
    background: white;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.signin button {
    border-radius: 20px;
    border: 1px solid #585858;
    background-color: #585858;
    width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 100ms ease-in;
    outline: none;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none !important;
}

button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

.iconSocial{
    top: -1px;
    width: 1rem;
    margin-right: 12px;
    position: relative;
}
