
.bg-main {
    background: #CBCBCB !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c0c0c0;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c0c0c0;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #c0c0c0;
}

.font-weight-medium {
    font-weight: 500 !important
}

.font-weight-semibold {
    font-weight: 600 !important
}

.ant-modal-content {
    border-radius: .7rem !important;
    border: 1px solid #585858;
}

.ant-modal-header {
    border-radius: .7rem !important;
    border: 0;
}

.ant-modal-footer {
    border: 0;
    margin-bottom: 24px;
    padding: 8px 24px;
}

.ant-modal-body {
    padding: 8px 24px;
}

.circle-btn {
    width: 26px !important;
    height: 26px !important;
    padding: 0 !important;
    float: left;
}

.circle-btn-icon {
    font-size: 18px;
    vertical-align: middle;
    margin-top: -4px;
}

.circle-ant-btn {
    color: #fff;
    background-color: #444444;
    border-color: #444444;
    width: 26px !important;
    min-width: 26px !important;
    height: 26px !important;
}
.circle-ant-btn-search {
    color: #fff;
    background-color: #444444;
    border-color: #444444;
    width: 38px !important;
    min-width: 38px !important;
    height: 38px !important;
}

.circle-ant-btn:hover, .circle-ant-btn:active, .circle-ant-btn:focus {
    color: #fff !important;
    background-color: #2e2e2e !important;
    border-color: #2e2e2e !important;
}

.circle-ant-btn-icon {
    vertical-align: 0.125em
}

.dart-btn {
    width: 150px;
    height: 36px;
    max-height: 44px;
    background-color: #585858;
    border-color: #585858;
}

.dart-btn:hover {
    background-color: #2e2e2e;
    border-color: #2e2e2e;
}

.dart-btn-outline {
    min-width: 150px;
    height: 36px;
    color: #585858;
    border-color: #585858;
}

.dart-btn-outline:hover {
    color: #fff;
    border-color: #2e2e2e;
    background-color: #2e2e2e;
}

.dart-btn-outline:disabled {
    opacity: 0.3;
}

.ant-modal-mask {
    background: rgba(0, 0, 0, 0.35);
}


.nav-link.active {
    background: #CBCBCB;
    border-color: #CBCBCB;
}

.nav-link {
    background: #F6F6F6;
    border-color: #F6F6F6;
}

.nav-link:hover {
    background: #CBCBCB;
}


.nav-item {
    margin-bottom: 0 !important;
}

.bg-dart {
    background: #CBCBCB !important;
}

.labelForFile {
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.collectionItem {
    background: #F6F6F6;
    border-color: #707070 !important;

}

.collectionItem:last-child {
    border-bottom: 1px solid #707070 !important;
}


.minHeight {
    min-height: 20rem;
}

.bg-gray-75 {
    background: #cbcbcb;
}

.br-gray-50 {
    background: #e9e9e9 !important;
}

.bg-gray-25 {
    background: #e9e9e9 !important;
}

.isOpacity {
    opacity: 0 !important;
}

.ans-status-icon {
    font-size: 1.2rem !important;
    vertical-align: 0 !important;
    color: #999999;
}

.ans-status-full {
    color: #009E00;
}

.ans-status-part {
    color: #e99700;
}

.ace_scroller.ace_scroll-left {
    box-shadow: none !important;
}

.ace_hidden-cursors {
    opacity: 0;
}

.ace_marker-layer .ace_bracket {
    display: none !important;
}

.timesBtn {
    width: 1.25rem;
    height: 1.25rem;
    -webkit-transition: -webkit-transform 100ms ease-in;
    transition: -webkit-transform 100ms ease-in;
    transition: transform 100ms ease-in;
}

.timesBtn:active {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.cursorPointer {
    cursor: pointer;
}

.helpForLabel {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.3s;
    margin-left: .2rem;
}

.helpForLabel:hover {

    background-color: #444444;
    border-color: #444444;
    color: white;
}

.blockPermissions {
    width: 150px;
    border: 1px solid #CBCBCB;
}

.animeteScale {
    -webkit-transition: -webkit-transform 100ms ease-in;
    transition: -webkit-transform 100ms ease-in;
    transition: transform 100ms ease-in;
}

.animeteScale:active {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}


.link-knackly {
    cursor: pointer;
}

.link-knackly:hover {
    text-decoration: underline;
}


.theme-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.theme-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.theme-popover {
    position: absolute;
    z-index: 2;
}

.theme-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.rot {
    animation: 2s linear 0s normal none infinite running rot;
    -webkit-animation: 2s linear 0s normal none infinite running rot;
    width: 100px;
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.t-success {
    background: white;
}

.menuDeleteLayout {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}

.cardLayout:hover > .menuDeleteLayout {
    visibility: visible;
    opacity: 1;
}

.sortArrow {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}

.blockSort:hover > .menuDeleteLayout {
    visibility: visible;
    opacity: 1;
}

.blockSort.active > .menuDeleteLayout {
    visibility: visible;
    opacity: 1;
}

TD.md, TH.md {
    padding: 4px 12px 0px 0px;
}

.app-modal {
    width: 60%;
}

@media (min-width: 768px) {
    .app-modal {
        width: 95% !important;
    }
}

@media (min-width: 992px) {
    .app-modal {
        width: 85% !important;
    }
}

@media (min-width: 1094px) {
    .app-modal {
        width: 75% !important;
    }
}

@media (min-width: 1200px) {
    .app-modal {
        width: 80% !important;
    }
}

.TemplateWrapper {
    height: auto !important;
}

.opacity-3 {
    opacity: .3 !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.warning-tooltip > .ant-tooltip-content > .ant-tooltip-arrow {
    border-bottom-color: rgba(220, 53, 69, 0.87);
}

.warning-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
    background-color: rgba(220, 53, 69, 0.87);
}

.ace_placeholder {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    transform: none !important;
    opacity: 0.7;
    margin: 0;
}

.animate__animated {
    -webkit-animation-duration: calc(1s * 0.8);
    animation-duration: calc(1s * 0.8);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes rotateDown {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes rotateDown {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.animate__rotateDown {
    -webkit-animation-name: rotateDown;
    animation-name: rotateDown;
}

.separate {
    padding: 0;
    position: relative;
    margin: 15px auto;
}

.separate:before {
    height: 1px;
    background-color: #ced4da;
    margin: 10px 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
}

.separate p {
    background-color: #fff;
    display: table;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 0 15px;
}

.elements-menu {
    visibility: hidden;
    opacity: 0;
}

.elements:hover > .elements-menu {
    visibility: visible;
    opacity: 1;
}
.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
